<template>
  <div class="absolute top-0 left-0 right-0 bottom-0 z-40 flex overflow-hidden">
    <div ref="share-screen-container" class="flex-1 flex flex-col overflow-hidden flex-shrink-0 relative">
      <div
        v-if="activeScreenPod"
        :key="activeScreenPod.key"
        class="overflow-hidden w-full"
        :style="{ height: screenHeight + 'px' }"
      >
        <BlockRemoteStreamScreen
          :key="activeScreenPod.id"
          :attendee="attendee"
          :pod="activeScreenPod"
          :with-fullscreen="true"
          :as-webinar="false"
          :fill-pod="true"
          size="md"
        />
      </div>
      <div
        class="absolute justify-start items-start left-0 right-0 grid"
        :style="{ top: (isSharingScreen ? screenHeight : 0) + 'px' }"
        :class="{
          'h-screen': !isSharingScreen,
          [gridClass]: !isSharingScreen,
          'bottom-1 left-1 grid-rows-1 grid-cols-12 gap-1': isSharingScreen,
        }"
      >
        <div
          v-for="pod in pageActivePods"
          :key="pod.id"
          class="overflow-hidden w-full"
          :style="{ maxHeight: maxPodHeight, height: height }"
        >
          <BlockRemoteStreamScreen
            :key="pod.id"
            :pod="pod"
            :pods-length="podsLength"
            :attendee="attendee"
            :with-fullscreen="false"
            :as-webinar="false"
            :fill-pod="true"
            :size="isSharingScreen ? 'xs' : 'md-2'"
            :show-name="!isSharingScreen"
            :show-hd="false"
            :show-guest-info="false"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-4 items-center py-4 pr-4 pl-1 w-80">
      <div class="px-7 pb-7 flex flex-col justify-around gap-10 flex-1">
        <DigitalDesk class="h-5" />
        <div
          v-if="currentHour"
          class="flex flex-col pl-2 gap-3 items-center text-center whitespace-nowrap text-5xl font-bold"
        >
          <div class="flex font-bold items-center text-5xl">
            <fw-icon-clock-line class="w-11 h-11 mr-2" />
            {{ currentHour }}
            <span class="animate-pulse">:</span>
            {{ currentMinutes }}
          </div>
        </div>
        <div class="flex flex-col gap-4 text-center">
          <v-clamp autoresize :max-lines="1" class="font-mono opacity-70 text-4xl font-semibold">
            {{ meeting.alias && meeting.alias.length ? meeting.alias[0] : meeting.key }}
          </v-clamp>
        </div>
        <div
          v-if="duration"
          class="flex flex-col pl-2 gap-3 items-center text-center whitespace-nowrap text-5xl font-bold"
        >
          <div class="opacity-30 text-lg">Tempo em sessão</div>
          {{ duration }}
          <div class="font-medium text-lg">Iniciou às {{ meetingStartTime }}</div>
        </div>
      </div>
      <div
        class="rounded-xl flex flex-col items-stretch justify-center gap-1 p-5 text-center w-full h-52 relative"
        :class="{
          'bg-primary': handsupUnatended > 0,
          'bg-gray-800': handsupUnatended == 0,
        }"
      >
        <fw-icon-hand-raised2 class="w-14 h-14 absolute top-5 left-5" />
        <div class="text-7xl font-black">
          {{ handsupUnatended }}
        </div>
        <div class="text-lg font-bold text-center absolute bottom-5 left-5 right-5">Pessoas a pedir a palavra</div>
      </div>
      <div
        class="rounded-xl flex flex-col items-stretch justify-center gap-1 p-5 text-center w-full h-52 relative"
        :class="{
          'bg-primary animate-pulse': askingToEnter > 0,
          'bg-gray-800 opacity-20': askingToEnter === 0,
        }"
      >
        <fw-icon-door class="w-14 h-14 absolute top-5 left-5" />
        <div class="text-7xl font-black">
          {{ askingToEnter }}
        </div>
        <div class="text-lg font-bold text-center absolute bottom-5 left-5 right-5">Pessoas a pedir para entrar</div>
      </div>
      <div
        class="rounded-xl flex flex-col items-stretch justify-center gap-1 p-5 text-center w-full h-52 bg-gray-800 relative"
      >
        <fw-icon-map-pin-user class="w-14 h-14 absolute top-5 left-5" />
        <div class="text-7xl font-black">
          {{ numberAttendes }}
        </div>
        <div class="text-lg font-bold text-center absolute bottom-5 left-5 right-5">Pessoas online</div>
      </div>
      <div
        v-if="totalScreenPods.length > 1"
        class="rounded-xl px-5 py-3 flex w-full items-center gap-5 bottom-0 text-lg right-0 bg-yellow-400 text-black font-bold"
      >
        <svg class="h-10 w-10 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z"
          ></path>
        </svg>
        {{ totalScreenPods.length }} partilhas remotas
      </div>
    </div>
  </div>
</template>
<script>
import DigitalDesk from '@/components/DigitalDesk'
import BlockRemoteStreamScreen from '@/components/blocks/BlockRemoteStreamScreen'
export default {
  name: 'PanelScreenShare',
  components: {
    BlockRemoteStreamScreen,
    DigitalDesk,
  },
  props: {
    pods: {
      type: Array,
      default: () => [],
    },
    activePods: {
      type: Array,
      default: () => [],
    },
    attendee: {
      type: Object,
    },
    meeting: {
      type: Object,
    },
    waitingAttendees: {
      type: Array,
      default: () => [],
    },
    attendeesHandsUp: {
      type: Array,
      default: () => [],
    },
    duration: {
      type: String,
      default: '--:--:--',
    },
  },
  data() {
    return {
      meetingStartTime: '--:--',
      currentHour: null,
      currentMinutes: null,
      screenHeight: 650,
    }
  },
  computed: {
    maxPodHeight() {
      let windowHeight = window.innerHeight
      return this.isSharingScreen ? 'none' : windowHeight / this.gridRows + 'px'
    },
    height() {
      return this.isSharingScreen ? '100%' : this.maxPodHeight
    },
    gridClass() {
      return 'grid-cols-' + this.gridCols + ' grid-rows-' + this.gridRows
    },
    activeScreenPod() {
      const firstPod = this.activePods[0]
      if (firstPod && firstPod.type === 'screen_share' && !firstPod.isUcMiddleware) {
        return firstPod
      }
      return null
    },
    isSharingScreen() {
      return !!this.activeScreenPod
    },
    pageActivePods() {
      if (this.isSharingScreen || (this.activePods.length > 0 && this.activePods[0].isUcMiddleware)) {
        return this.activePods.slice(1)
      }
      return this.activePods
    },
    podsLength() {
      return this.pageActivePods.length //this.activePods.length
    },
    gridCols() {
      //if (this.isSharingScreen) return 12
      if (this.podsLength == 1) return 1
      if (this.podsLength <= 4) return 2
      if (this.podsLength <= 6) return 3
      if (this.podsLength <= 8) return 4
      if (this.podsLength <= 12) return 4
      if (this.podsLength <= 15) return 5
      if (this.podsLength <= 20) return 5
      return 6
    },
    gridRows() {
      if (this.isSharingScreen) return 1
      if (this.podsLength <= 2) return 1
      if (this.podsLength <= 6) return 2
      if (this.podsLength <= 8) return 2
      if (this.podsLength <= 12) return 3
      if (this.podsLength <= 15) return 3
      if (this.podsLength <= 20) return 4
      return 4
    },
    totalScreenPods() {
      return this.pods.filter(pod => pod.type == 'screen_share' && !pod.isUcMiddleware)
    },
    askingToEnter() {
      return this.waitingAttendees.length
    },
    numberAttendes() {
      return this.pods.filter(i => i.type == 'attendee' && !i.isUcMiddleware).length
    },
    attendeesWithaudioPermission() {
      return this.attendeesHandsupHidrated.filter(attendee => attendee.withRole('audio_allowed')).length
    },
    handsupUnatended() {
      return this.attendeesHandsUp.length
    },
    attendeesHandsupHidrated() {
      return this.attendeesHandsUp.map(attendeeId => {
        return this.attendees[attendeeId]
      })
    },
  },
  mounted() {
    this.startClock()
    this.calculateScreenShareHeight()
    //listen to windows resize
    window.addEventListener('resize', this.calculateScreenShareHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateScreenShareHeight)
  },
  methods: {
    calculateScreenShareHeight() {
      let containerWidth = this.$refs['share-screen-container'].offsetWidth
      this.screenHeight = containerWidth / (16 / 9) + 60
    },
    updateTime() {
      let date = new Date()
      this.currentHour = date.toLocaleTimeString('pt-PT', {
        hour: '2-digit',
      })
      this.currentMinutes = this.checkSingleDigit(
        date.toLocaleTimeString('pt-PT', {
          minute: '2-digit',
        })
      )
    },
    checkSingleDigit(digit) {
      return ('0' + digit).slice(-2)
    },
    startClock() {
      this.updateTime()
      if (this.meetingStartTime == '--:--') this.meetingStartTime = this.currentHour + ':' + this.currentMinutes
      setInterval(() => {
        this.updateTime()
      }, 1000)
    },
  },
}
</script>
