<template>
  <div id="app" data-muted="true">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    //parse url options
    let location = window.location.href
    let url = new URL(location)
    let showInfo = url.searchParams.get('show-info')
    let isVertical = url.searchParams.get('is-vertical')
    let isWideScreen = url.searchParams.get('is-wide-screen')
    let screenNumber = url.searchParams.get('screen-number')
    let totalScreens = url.searchParams.get('total-screens')
    let showScreenShare = url.searchParams.get('show-screenshare')
    let onlyVideo = url.searchParams.get('only-video')
    if (showInfo == 'true') {
      localStorage.setItem('show-info', true)
    } else if (showInfo == 'false') {
      localStorage.setItem('show-info', false)
    }
    let screenKey = url.searchParams.get('key')
    let screenToken = url.searchParams.get('token')
    let screenName = url.searchParams.get('name')

    if (screenKey && screenToken && screenName) {
      let data = {
        key: screenKey,
        token: screenToken,
        name: screenName,
      }
      localStorage.setItem('meetings.subscribed.room', JSON.stringify(data))
    }
    if (isVertical == 'true') {
      localStorage.setItem('is-vertical', 'true')
    } else if (isVertical == 'false') {
      localStorage.removeItem('is-vertical')
    }
    if (onlyVideo == 'true') {
      localStorage.setItem('only-video', 'true')
    } else if (onlyVideo == 'false') {
      localStorage.removeItem('only-video')
    }
    if (isWideScreen == 'true') {
      localStorage.setItem('show-widescreen', 'true')
    } else if (isWideScreen == 'false') {
      localStorage.removeItem('show-widescreen')
    }
    if (screenNumber) {
      localStorage.setItem('screenNumber', screenNumber)
    }
    if (totalScreens) {
      localStorage.setItem('totalScreens', totalScreens)
    }
    if (showScreenShare == 'true') {
      localStorage.setItem('show-screenshare', true)
    } else if (showScreenShare == 'false') {
      localStorage.setItem('show-screenshare', false)
    }
  },
}
</script>

<style>
.bg-panel {
  background-color: #202020;
}
</style>
