<template>
  <LayoutDefault v-if="meetingNotFound">
    <template #main-content>
      <div class="fixed bottom-1 right-2 text-xs text-white opacity-20 z-0">{{ appName }} {{ appVersion }}</div>
      <div class="h-full flex flex-col items-center justify-center">
        <div class="has-margin-medium">
          <faicon icon="heart-broken" size="3x"></faicon>
        </div>
        <div class="has-text-tiny">Erro 404.</div>
        <h1 class="is-size-2">A sala não existe</h1>
        <div class="has-margin-top-large">
          <b-button type="is-text is-small has-text-muted" @click="$router.go(-1)">Voltar à página anterior</b-button>
        </div>
      </div>
    </template>
  </LayoutDefault>
  <LayoutDefault
    v-else
    :class="{ 'is-sharing-screen': screenShare.active }"
    :full="true"
    :main-sidebar-sm="false"
    :main-sidebar-fixed="false"
    :header-apps="false"
    :header-notifications="false"
    :keep-tapbar="false"
    :header-session-nav="false"
    :header-avatar="true"
    :header-toolbar="false"
    :header="false"
    :disable-back-to="isRunning"
    back-to="-1"
    dark
  >
    <template #main-content>
      <div class="fixed bottom-1 right-2 text-xs text-white opacity-20 z-0">{{ appName }} {{ appVersion }}</div>
      <div class="h-full flex items-center justify-center">
        <div v-if="isRunning" class="flex w-full h-full">
          <PanelWideScreen
            v-if="showWideScreen"
            :active-pods="activePods"
            :pods="pods"
            :meeting="meeting"
            :attendees="attendees"
            :attendee="attendee"
            :as-webinar="asWebinar"
            :duration="duration"
            :waiting-attendees="waitingAttendees"
            :free-microphones="freeMicrophones"
            :attendees-hands-up="attendeesHandsUp"
            :is-class-edition="isClassEdition"
          />
          <PanelMeetingInfo
            v-else-if="showMeetingInfo"
            :active-pods="activePods"
            :pods="pods"
            :meeting="meeting"
            :attendees="attendees"
            :attendee="attendee"
            :as-webinar="asWebinar"
            :duration="duration"
            :waiting-attendees="waitingAttendees"
            :free-microphones="freeMicrophones"
            :attendees-hands-up="attendeesHandsUp"
            :is-class-edition="isClassEdition"
          />
          <PanelScreenShare
            v-else-if="showScreenShare"
            :with-fullscreen="withFullscreen"
            :pods="pods"
            :active-pods="activePods"
            :attendee="attendee"
            :meeting="meeting"
            :attendees="attendees"
            :waiting-attendees="waitingAttendees"
            :attendees-hands-up="attendeesHandsUp"
            :duration="duration"
          ></PanelScreenShare>
          <!-- {{ talkingNames }}  {{ attendeesHandsUp }} {{ activePods.length }}  {{ pods.length }}
          {{ waitingAttendees.length }} -->
          <PanelParticipantsOnlyVideo
            v-else-if="onlyVideo"
            :pods="activePods"
            :with-janus="withJanus"
            :attendee="attendee"
            :with-fullscreen="withFullscreen"
            :as-webinar="asWebinar"
            :fill-pod="pageSettings.fillPod"
            @mute-attendee="muteAttendee"
            @kick-attendee="kickAttendee"
            @toggle-fullscreen="toggleFullscreen"
            @toggle-promote="togglePromote"
            @go-to-user-chat="goToUserChat"
          />
          <PanelParticipants
            v-else
            :pods="activePods"
            :with-janus="withJanus"
            :attendee="attendee"
            :with-fullscreen="withFullscreen"
            :as-webinar="asWebinar"
            :fill-pod="pageSettings.fillPod"
            @mute-attendee="muteAttendee"
            @kick-attendee="kickAttendee"
            @toggle-fullscreen="toggleFullscreen"
            @toggle-promote="togglePromote"
            @go-to-user-chat="goToUserChat"
          />
        </div>
      </div>
    </template>

    <template #modals>
      <div id="audio-streams" class="is-hidden"></div>
    </template>
  </LayoutDefault>
</template>

<script>
import BaseLive from '@/fw-modules/fw-meetings-vue/mixins/BaseLive'
import ChatLive from '@/fw-modules/fw-core-vue/chats/mixins/ChatLive'
import JanusLive from '@/fw-modules/fw-meetings-vue/mixins/JanusLive'
import JanusSelfLive from '@/fw-modules/fw-meetings-vue/mixins/JanusSelfLive'
import JanusSubscribersLive from '@/fw-modules/fw-meetings-vue/mixins/JanusSubscribersLive'
import PodsLive from '@/fw-modules/fw-meetings-vue/mixins/PodsLive'
import PanelParticipants from '@/components/panels/PanelParticipantsScreen'
import PanelParticipantsOnlyVideo from '@/components/panels/PanelParticipantsScreenOnlyVideo'
import PanelScreenShare from '@/components/panels/PanelScreenShare'
import PanelWideScreen from '@/components/panels/PanelWideScreen'
import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'

import PanelMeetingInfo from '@/components/panels/PanelMeetingInfo'
export default {
  components: {
    LayoutDefault,
    PanelParticipants,
    PanelMeetingInfo,
    PanelScreenShare,
    PanelWideScreen,
    PanelParticipantsOnlyVideo,
  },

  mixins: [BaseLive, ChatLive, JanusLive, JanusSelfLive, JanusSubscribersLive, PodsLive],

  data() {
    return {
      showMeetingInfo: false,
      showScreenShare: false,
      showWideScreen: false,
      onlyVideo: false,
      isSpeaking: false,
      freeMicrophones: false,
      appVersion: process.env.VUE_APP_VERSION,
      appName: process.env.VUE_APP_KEY,
    }
  },

  watch: {
    instance: {
      handler(instance) {
        console.log('instance changed', instance)
        this.freeMicrophones = instance.withRole('audio_allowed')
      },
      deep: true,
    },
  },

  mounted() {
    this.freeMicrophones = this.instance && this.instance.withRole('audio_allowed')
    if (this.$device.isiOS) {
      window.addEventListener('resize', this.fix100vhiOSBug)
      this.fix100vhiOSBug()
    }
    this.showMeetingInfo = this.showUcMeetingScreenInfo()
    this.showScreenShare = this.showUcMeetingScreenShare()
    this.showWideScreen = this.showUcMeetingWideScreen()
    this.onlyVideo = localStorage.getItem('only-video') === 'true'
  },

  beforeDestroy() {
    if (this.$device.isiOS) {
      window.removeEventListener('resize', this.fix100vhiOSBug)
    }
  },

  methods: {
    getAttendeeName(key) {
      const attendee = this.attendees[key]
      if (attendee.name) {
        return attendee.name
      } else if (attendee.user) {
        return attendee.user.name
      } else {
        return attendee.key
      }
    },
    goToUserChat(user) {
      this.view = 'chat'
      this.chatOnLoadGoToUser = user
    },

    fix100vhiOSBug() {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
  },
}
</script>

<style>
.min-h-half {
  min-height: 50%;
}
.min-h-2thirds {
  min-height: 66.666667%;
}
.height-handsup {
  height: 23.5rem;
}
</style>
