var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{staticClass:"clear-bg fw-modal",class:{
    'min-h-full': !_vm.innerScroll,
  },attrs:{"active":_vm.isActive,"full-screen":"","scroll":"keep","trap-focus":"","aria-role":"dialog","aria-modal":"","destroy-on-hide":true,"can-cancel":_vm.canCancel},on:{"update:active":function($event){_vm.isActive=$event},"close":function($event){return _vm.close()}}},[_c('div',{staticClass:"flex min-h-full flex-col bg-black bg-opacity-40 backdrop-blur-sm backdrop-filter justify-center",class:{
      'overflow-y-auto': !_vm.innerScroll,
    }},[_c('div',{staticClass:"bg-white shadow-lg mx-auto rounded-xl border border-gray-100 relative flex flex-col gap-5 z-50 min-w-xs",class:[
        {
          'p-8': _vm.boxed === 'lg' && !_vm.paddingless,
          'p-1': _vm.boxed === 'xs' && !_vm.paddingless,
          'p-5': (!_vm.boxed || _vm.boxed === 'sm') && !_vm.paddingless,
          'max-h-screen': _vm.heightFitScreen,
          'w-full': _vm.width == null,
          'max-h-[44rem]': _vm.innerScroll,
        },
        !['full', 'min'].includes(_vm.size) ? `max-w-${_vm.size}` : _vm.size == 'full' ? 'w-full' : '',
      ],style:({ width: _vm.isMobile ? '100%' : _vm.width })},[(_vm.title || _vm.$slots['toolbar'])?_c('div',[_c('div',{staticClass:"flex gap-5"},[_c('div',{class:`flex flex-1 justify-${_vm.titleAlign}`},[_c('fw-heading',{attrs:{"muted":""}},[_vm._v(_vm._s(_vm.title))])],1),(_vm.$slots['toolbar'])?_c('div',{staticClass:"align-right"},[_vm._t("toolbar")],2):_vm._e()]),(_vm.description)?_c('div',{staticClass:"mt-2 text-gray-500 font-medium text-sm"},[_vm._v(_vm._s(_vm.description))]):_vm._e()]):_vm._e(),(_vm.$slots['default'])?_vm._t("default"):_vm._e(),(_vm.$slots['footer'])?_c('div',{staticClass:"flex justify-end"},[_vm._t("footer")],2):_vm._e(),(_vm.loading)?_c('div',{staticClass:"absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-10 backdrop-blur-sm backdrop-filter z-50"},[_c('fw-icon-loading',{staticClass:"w-8 h-8"})],1):_vm._e()],2),(_vm.canCancel)?_c('div',{staticClass:"absolute w-full h-full z-0",on:{"click":function($event){return _vm.close()}}}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }