<template>
  <div class="flex gap-4 w-full font-semibold h-full p-4 text-gray-200">
    <div class="flex flex-col text-3xl rounded-3xl gap-4" style="width: 32rem">
      <div class="flex flex-col h-full gap-3">
        <div class="px-7 pb-7 flex flex-col justify-around gap-10 flex-1">
          <DigitalDeskLogo />
          <div
            v-if="currentHour"
            class="flex flex-col pl-2 gap-3 items-center text-center whitespace-nowrap text-7xl font-bold"
          >
            <div class="opacity-30 text-xl">Hora atual</div>
            <div class="flex font-bold items-center text-6xl">
              <fw-icon-clock-line class="w-14 h-14 mr-2" />
              {{ currentHour }}
              <span class="animate-pulse">:</span>
              {{ currentMinutes }}
            </div>
          </div>
          <div class="flex flex-col gap-4 text-center">
            <v-clamp autoresize :max-lines="1" class="font-mono">
              {{ meeting.alias && meeting.alias.length ? meeting.alias[0] : meeting.key }}
            </v-clamp>
            <v-clamp autoresize :max-lines="2" class="text-2xl font-semibold">
              {{ meetingTitle }}
            </v-clamp>
            <v-clamp v-if="meeting.title != meetingTitle" autoresize :max-lines="1" class="text-xl font-bold">
              {{ meeting.title }}
            </v-clamp>
          </div>
          <div
            v-if="duration"
            class="flex flex-col pl-2 gap-3 items-center text-center whitespace-nowrap text-7xl font-bold"
          >
            <div class="opacity-30 text-xl">Tempo em sessão</div>
            {{ duration }}
            <div class="font-medium text-xl">Iniciou às {{ meetingStartTime }}</div>
          </div>
        </div>
        <div class="p-3 gap-3 bg-panel height-handsup rounded-2xl grid grid-cols-2 grid-rows-2">
          <div class="flex flex-col items-center gap-4 justify-center text-center rounded-2xl bg-gray-800">
            <div class="text-base text-white opacity-30">Vídeo</div>
            <div
              class="flex gap-3 items-center text-5xl font-extrabold"
              :class="{ 'text-primary': withVideo, 'text-red-600': !withVideo }"
            >
              <fw-icon-vidicon-solid class="w-12 h-12 flex-shrink-0" />
            </div>
            <div class="text-base font-semibold" :class="{ 'text-primary': withVideo, 'text-red-600': !withVideo }">
              {{ withVideo ? 'Ligado' : 'Desligado' }}
            </div>
          </div>
          <div class="flex flex-col items-center gap-4 justify-center text-center rounded-2xl bg-gray-800">
            <div class="text-base text-white opacity-30">Microfone</div>
            <div
              class="flex gap-3 items-center text-5xl font-extrabold"
              :class="{ 'text-primary': withMic, 'text-red-600': !withMic }"
            >
              <fw-icon-mic v-if="withMic" class="w-12 h-12 flex-shrink-0" />
              <fw-icon-mic-off v-else class="w-12 h-12 flex-shrink-0" />
            </div>
            <div class="text-base font-semibold" :class="{ 'text-primary': withMic, 'text-red-600': !withMic }">
              {{ withMic ? 'Ligado' : 'Desligado' }}
            </div>
          </div>
          <div class="flex flex-col items-center gap-4 justify-center text-center rounded-2xl bg-gray-800">
            <div class="text-base text-white opacity-30">MONITOR 1</div>
            <div
              class="flex gap-3 items-center text-5xl font-extrabold"
              :class="{ 'text-primary': screen1, 'opacity-50': !screen1 }"
            >
              <fw-icon-screen class="w-12 h-12 flex-shrink-0" />
              1
            </div>
            <div
              class="text-base font-semibold"
              :class="{ 'text-primary': screen1, ' text-white opacity-30': !screen1 }"
            >
              {{ screen1 ? 'A partilhar' : 'Não partilhado' }}
            </div>
          </div>
          <div class="flex flex-col items-center gap-4 justify-center text-center rounded-2xl bg-gray-800">
            <div class="text-base text-white opacity-30">MONITOR 2</div>
            <div
              class="flex gap-3 items-center text-5xl font-extrabold"
              :class="{ 'text-primary': screen2, 'opacity-50': !screen2 }"
            >
              <fw-icon-screen class="w-12 h-12 flex-shrink-0" />
              2
            </div>
            <div
              class="text-base font-semibold"
              :class="{ 'text-primary': screen2, ' text-white opacity-30': !screen2 }"
            >
              {{ screen2 ? 'A partilhar' : 'Não partilhado' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-4 flex-1 h-full">
      <div class="relative flex-1 overflow-hidden">
        <div
          class="absolute z-50 top-5 text-2xl font-extrabold right-5 bg-white bg-opacity-20 px-3 pl-5 py-2 rounded-lg"
        >
          <div class="relative pr-4">
            Live
            <div class="bg-white animate-pulse h-2 w-2 absolute top-1 right-1 rounded-full"></div>
          </div>
        </div>
        <BlockRemoteStreamScreen
          v-if="myPod"
          :key="myPod.key"
          :main="true"
          :pod="myPod"
          :pods-length="1"
          :attendee="attendee"
          :with-fullscreen="false"
          :as-webinar="asWebinar"
          :fill-pod="true"
          :borderless="true"
          :style="{ maxHeight: maxHeightVideo + 'px' }"
        />
      </div>
      <div class="flex gap-5 height-handsup flex-shrink-0">
        <div
          class="rounded-xl flex flex-col items-stretch justify-center gap-1 p-5 text-center w-1/3 relative"
          :class="{
            'bg-primary': handsupUnatended > 0,
            'bg-gray-800': attendeesWithaudioPermission > 0 && handsupUnatended == 0,
            'bg-gray-800 opacity-20': handsupUnatended == 0 && attendeesWithaudioPermission == 0,
          }"
        >
          <div class="flex justify-between absolute top-5 left-5 right-7 items-center text-6xl">
            <fw-icon-hand-raised2 class="w-20 h-20" />
            {{ attendeesWithaudioPermission }}
          </div>
          <div class="text-8xl font-black">
            {{ handsupUnatended }}
          </div>
          <div class="text-lg font-bold text-center absolute bottom-5 left-5 right-5">Pessoas a pedir a palavra</div>
        </div>
        <div
          class="rounded-xl flex flex-col items-stretch justify-center gap-1 p-5 text-center w-1/3 relative"
          :class="{
            'bg-primary animate-pulse': askingToEnter > 0,
            'bg-gray-800 opacity-20': askingToEnter === 0,
          }"
        >
          <fw-icon-door class="w-20 h-20 absolute top-5 left-5" />
          <div class="text-8xl font-black">
            {{ askingToEnter }}
          </div>
          <div class="text-lg font-bold text-center absolute bottom-5 left-5 right-5">Pessoas a pedir para entrar</div>
        </div>
        <div
          class="rounded-xl flex flex-col items-stretch justify-center gap-1 p-5 text-center w-1/3 bg-gray-800 relative"
        >
          <fw-icon-map-pin-user class="w-20 h-20 absolute top-5 left-5" />
          <div class="text-8xl font-black">
            {{ numberAttendes }}
          </div>
          <div class="text-lg font-bold text-center absolute bottom-5 left-5 right-5">Pessoas online</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlockRemoteStreamScreen from '@/components/blocks/BlockRemoteStreamScreen'
import DigitalDeskLogo from '@/components/DigitalDesk'
export default {
  name: 'PanelMeetingInfo',
  components: {
    BlockRemoteStreamScreen,
    DigitalDeskLogo,
  },
  props: {
    activePods: {
      type: Array,
      default: () => [],
    },
    pods: {
      type: Array,
      default: () => [],
    },
    meeting: {
      type: Object,
      default: () => {},
    },
    attendees: {
      type: Object,
      default: () => {},
    },
    waitingAttendees: {
      type: Array,
      default: () => [],
    },
    attendeesHandsUp: {
      type: Array,
      default: () => [],
    },
    attendee: {
      type: Object,
    },
    asWebinar: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: String,
      default: '--:--:--',
    },
    isClassEdition: {
      type: Boolean,
      default: false,
    },
    freeMicrophones: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      meetingStartTime: '--:--',
      currentHour: null,
      currentMinutes: null,
      maxHeightVideo: '528',
    }
  },
  computed: {
    withMic() {
      if (this.myPod) {
        return this.myPod.withAudio
      } else {
        return false
      }
    },
    withVideo() {
      if (this.myPod) {
        return this.myPod.video != null
      } else {
        return false
      }
    },
    meetingTitle() {
      return !this.isClassEdition ? this.meeting.title : this.meeting.context.unit.title
    },
    askingToEnter() {
      return this.waitingAttendees.length
    },
    numberAttendes() {
      return this.pods.filter(i => i.type == 'attendee' && !i.isUcMiddleware).length
    },
    attendeesWithaudioPermission() {
      return this.attendeesHandsupHidrated.filter(attendee => attendee.withRole('audio_allowed')).length
    },
    handsupUnatended() {
      return this.attendeesHandsUp.length - this.attendeesWithaudioPermission
    },
    attendeesHandsupHidrated() {
      return this.attendeesHandsUp.map(attendeeId => {
        return this.attendees[attendeeId]
      })
    },
    myPod() {
      return this.activePods.length > 0 ? this.activePods[0] : null
    },
    screenPods() {
      return this.pods.filter(pod => pod.type == 'screen_share')
    },
    screenPodsLenght() {
      return this.screenPods.length
    },
    screen1() {
      return (
        typeof this.screenPods.find(
          element =>
            element.name.toLowerCase().includes('primário') ||
            element.name.toLowerCase().includes('primary') ||
            element.name.toLowerCase().includes('principal')
        ) != 'undefined'
      )
    },
    screen2() {
      return (
        typeof this.screenPods.find(
          element =>
            element.name.toLowerCase().includes('secundário') || element.name.toLowerCase().includes('secondary')
        ) != 'undefined'
      )
    },
  },
  created() {
    // this.maxHeightVideo = window.innerHeight - 340
  },
  mounted() {
    this.startClock()
  },
  methods: {
    updateTime() {
      let date = new Date()
      this.currentHour = date.toLocaleTimeString('pt-PT', {
        hour: '2-digit',
      })
      this.currentMinutes = this.checkSingleDigit(
        date.toLocaleTimeString('pt-PT', {
          minute: '2-digit',
        })
      )
    },
    checkSingleDigit(digit) {
      return ('0' + digit).slice(-2)
    },
    startClock() {
      this.updateTime()
      if (this.meetingStartTime == '--:--') this.meetingStartTime = this.currentHour + ':' + this.currentMinutes
      setInterval(() => {
        this.updateTime()
      }, 1000)
    },
  },
}
</script>
